import type { InternalApi } from 'nitropack'

const { setPopup } = usePopup()

export const useArtifactStore = defineStore('artifact', () => {
  const accountStore = useAccountStore()

  type Artifacts = InternalApi['/api/artifacts/']['get']
  type Artifact = InternalApi['/api/artifacts/:id/']['get']
  const originalArtifacts: Ref<Artifacts | undefined> = ref()
  const filteredArtifacts: Ref<Artifacts | undefined> = ref()
  const displayedArtifact: Ref<Artifact | undefined> = ref()
  const isDownloadedFilter = ref<number | null>(null)
  const isDownloading = ref(false)

  const getArtifactsByUserId = async (pageNumber: number) => {
    const data = await $fetch(`/api/artifacts/?pageNumber=${pageNumber}`)

    if (data?.length) {
      const newArtifacts = data

      const uniqueArtifacts = newArtifacts.filter(newArtifact =>
        !originalArtifacts.value?.some(existingArtifact => existingArtifact.id === newArtifact.id),
      )

      if (Array.isArray(originalArtifacts.value)) {
        originalArtifacts.value = [...originalArtifacts.value!, ...uniqueArtifacts]
      } else {
        originalArtifacts.value = uniqueArtifacts
      }

      if (Array.isArray(filteredArtifacts.value)) {
        const filteredNewArtifacts = uniqueArtifacts.filter(artifact => artifact.isActive)
        filteredArtifacts.value = [...filteredArtifacts.value!, ...filteredNewArtifacts]
      } else {
        filteredArtifacts.value = uniqueArtifacts.filter(artifact => artifact.isActive)
      }
    }
  }

  const filterArtifactsById = (artifactId: number) => {
    filteredArtifacts.value = filteredArtifacts.value?.filter(artifact => artifact.id !== artifactId)
  }

  const clearArtifacts = () => {
    originalArtifacts.value = []
    filteredArtifacts.value = []
  }

  const reverseArtifacts = () => {
    originalArtifacts.value = originalArtifacts.value?.reverse()
    filteredArtifacts.value = filteredArtifacts.value?.reverse()
  }

  const getArtifactWithTags = async (artifactId: number) => {
    const data = await $fetch(`/api/artifacts/${artifactId}/`, {
      method: 'GET',
    })
    displayedArtifact.value = data
    return data
  }

  const updateSourceForDownloadedArtifact = ({ artifactId, src }: { artifactId: number, src: string }) => {
    const artifactIndex = filteredArtifacts.value!.findIndex(
      artifact => artifact.id === artifactId,
    )
    if (artifactIndex !== -1) {
      filteredArtifacts.value![artifactIndex].source = src
      filteredArtifacts.value![artifactIndex].isDownloaded = 1
    }
    if (artifactId === displayedArtifact.value?.id) {
      getArtifactWithTags(artifactId)
    }
  }

  const applyFilter = () => {
    if (isDownloadedFilter.value === null) {
      return filteredArtifacts.value = originalArtifacts.value
    }
    filteredArtifacts.value = originalArtifacts.value?.filter(artifact => artifact.isDownloaded === isDownloadedFilter.value)
  }

  watch(isDownloadedFilter, (newStatusFilter, oldStatusFilter) => {
    if (newStatusFilter !== oldStatusFilter) { applyFilter() }
  })

  const filterArtifactsByDownloadStatus = (statusFilter: number | null) => {
    if (statusFilter === 0 || statusFilter === 1 || statusFilter === null) {
      isDownloadedFilter.value = statusFilter
    }
  }

  const downloadArtifact = async (artifactId: number) => {
    try {
      const { planId, salesDownloadLimit, downloadCredit } = await $fetch('/api/users/me/', {
        method: 'GET',
      })

      const result = await $fetch(`/api/artifacts/${artifactId}/download/`, {
        method: 'POST',
        body: {
          planId,
          salesDownloadLimit,
          downloadCredit,
        },
      })
      if (result.status === 200) {
        downloadImage(result.src)
        await updateSourceForDownloadedArtifact({ artifactId, src: result.src })

        setPopup('ダウンロードが完了しました。', 'green')
      }
    } catch (error: any) {
      if (error.status === 400) {
        setPopup('ダウンロードの利用が制限されています', 'red')
      }

      if (error.status === 500) {
        setPopup('処理中にサーバーエラーが発生しました。再試行してください。', 'red')
      }
    }
    await accountStore.fetchUser()
  }

  const purchaseAndArtifactDownload = async (artifactId: number) => {
    if (isDownloading.value) { return }

    try {
      isDownloading.value = true

      const response = await $fetch('/api/stripe/single-payment/', {
        method: 'POST',
      })

      if (response.status === 200) {
        await downloadArtifact(artifactId)
      }
    } catch (error: any) {
      if (error.status === 400) {
        setPopup('ご利用のためには、サブスクリプションを開始してください。', 'red')
      }
      if (error.status === 410) {
        setPopup('ご利用のサブスクリプションが停止しているか、確認できません。', 'red')
      }
      if (error.status === 500) {
        setPopup('処理中にサーバーエラーが発生しました。再試行してください。', 'red')
      }
    } finally {
      isDownloading.value = false
    }
  }

  return {
    filteredArtifacts,
    displayedArtifact,
    isDownloading,
    getArtifactsByUserId,
    clearArtifacts,
    reverseArtifacts,
    getArtifactWithTags,
    updateSourceForDownloadedArtifact,
    filterArtifactsByDownloadStatus,
    filterArtifactsById,
    downloadArtifact,
    purchaseAndArtifactDownload,
  }
})
